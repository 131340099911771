.App{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    height:100vh;
}

.text-centre{
    text-align: center;
    color: #4D220E;
}

.menu-contact{
    border:none;
    background:none;
    font-size: 55px;
    }
    .icon-telegram {
      color: #0088cc; /* Цвет для Telegram */
      margin-left:15px;
    }
    
    .icon-whatsapp {
      color: #25d366; /* Цвет для WhatsApp */
      margin-left:15px;
    }

    .icon-mail {
        color: #0512cf; /* Цвет для WhatsApp */
        margin-left:15px;
      }

      .icon-inst {
        color: #cc3535; /* Цвет для WhatsApp */
        margin-left:15px;
      }

      body, html {
        margin: 0;
        padding: 0;
        height: 100%;
      }
      
      .background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-image: url("./balm.jpg"); /* Здесь исправлено */
        background-size: cover;
        background-repeat: no-repeat;
        transition: background-image 1s ease;
      }
      
      .app1{
        background-color: rgb(244 240 240 / 90%);
        border: 1px solid rgb(244 240 240 / 50%);
        border-radius: 50px;
        height: 300px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 10px;
      }